:not(:defined) > * {
  display: none;
}

body {
  padding: none;
  margin: none;
}

model-viewer {
  width: 100%;
  height: 99vh;
  background-color: #eee;
  overflow-x: hidden;
  position: relative;
}

.item-info {
  position: absolute;
  bottom: 0;
  padding: 20px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

#ar-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;
}

#ar-button:active {
  background-color: #e8eaed;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}

model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 175px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status='session-started'] > #ar-prompt {
  display: block;
}

model-viewer > #ar-prompt > img {
  animation: circle 4s linear infinite;
}

model-viewer > #ar-failure {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 175px;
  display: none;
}

model-viewer[ar-tracking='not-tracking'] > #ar-failure {
  display: block;
}

#error {
  background-color: #ffffffdd;
  border-radius: 16px;
  padding: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 0.3s;
  z-index: 999;
}
#error.hide {
  opacity: 0;
  visibility: hidden;
  transition: visibility 3s, opacity 1s 1s;
}
